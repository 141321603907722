import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";
import { useLocalStorage } from "@vueuse/core";

export const useGeneralStore = defineStore("general-store", {
  state: () => ({
    isLoading: false,
    appId: 4,
    apps: {},
    links: [],
    loadingLogin: false,
    loginErrorMessage: "",
    darkSide: useLocalStorage("Qotsisajak", false),
    audioOn: useLocalStorage("audioOn", true),
  }),
  getters: {
    getApp: state => appKey => {
      if (!Object.keys(state.apps).includes(appKey)) {
        throw new Error(`apps object does not contain an app '${appKey}'`);
      }
      return state.apps[appKey];
    },
  },
  actions: {
    startLoading() {
      this.isLoading = true;
    },

    stopLoading() {
      this.isLoading = false;
    },

    login(email, password) {
      this.loadingLogin = true;
      $axios.post("login", {
        app_id: this.appId,
        email: email,
        password: password,
      }).then(response => {
        if (response.data.success) {
          window.location.href = "/";
          return;
        }
        if (response.data.old_password) {
          this.loginErrorMessage = `You entered an old password, you changed it ${response.data.date_changed}`;
        } else {
          this.loginErrorMessage = "login failed";
        }
        this.loadingLogin = false;
      }).catch(error => {
        this.loginErrorMessage = this.parseError(error);
        this.loadingLogin = false;
      });
    },
    
    logout() {
      window.location.href = "/logout";
    },

    setApps(apps) {
      this.apps = apps;
    },

    parseError(error) {
      if (error.response.data.message) {
        if (error.response.data.message === "The password you entered is incorrect. Please try again.") {
          return "Incorrect password, please try again.";
        }
      }
      return "Login failed, please try again.";
    },
  },
});
