<template>
  <CommandCenter v-if="inCommand" />
  <WaitingRoom v-else />
</template>

<script>
import { useStateStore } from "@/stores/state-store";
import { mapState } from "pinia";
import CommandCenter from "@/pages/CommandCenter";
import WaitingRoom from "@/pages/WaitingRoom";

export default {
  name: "Home",
  components: { CommandCenter, WaitingRoom },
  computed: {
    ...mapState(useStateStore, [
      "inCommand",
    ]),
  },
  created() {
    useStateStore().startPolling();
  },
};
</script>
