<template>
  <Starfield>
    <iHeading v-if="isLoading">
      Loading
    </iHeading>
    <iColumn v-else>
      <iRow justify-content="space-between">
        <iRow width="hug">
          <iHeading variant="accent" @click="">
            R2-D2
          </iHeading>
          <iHeading variant="subtle">
            /
          </iHeading>
          <iHeading variant="subtle">
            {{ title }}
          </iHeading>
        </iRow>
        <iRow width="hug">
          <iHeading variant="accent" @click="">
            {{ myUser.name }} ({{ myUser.githubHandle }})
          </iHeading>
        </iRow>
      </iRow>
      <iRow width="hug">
        <iColumn>
          <slot />
        </iColumn>
      </iRow>
    </iColumn>
  </Starfield>
</template>

<script>
import Starfield from "@/components/Starfield";
import { useGeneralStore } from "@/stores/general-store";
import { useStateStore } from "@/stores/state-store";

export default {
  name: "PageWrapper",
  components: { Starfield },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return useGeneralStore().isLoading;
    },
    myUser() {
      const x = useStateStore().myUser;
      return x;
    },
  },
};
</script>
