<template>
  <PageWrapper title="Waiting">
    <CurrentActivity />
    <Queue />
    <Monitoring />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import CurrentActivity from "@/components/CurrentActivity.vue";
import Queue from "@/components/Queue.vue";
import Monitoring from "@/components/Monitoring.vue";
export default {
  name: "WaitingRoom",
  components: {
    PageWrapper,
    CurrentActivity,
    Queue,
    Monitoring,
  },
};
</script>
