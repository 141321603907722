import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "@/stores/general-store";

export default defineStore("monitoring-store", {
  state: () => ({
    status: [],
    isFetching: false,
  }),
  getters: {
  },
  actions: {
    update(microservice, healthcheck) {
      useGeneralStore().isLoading = true;
      $axios.post(`/monitoring/${microservice}/healthcheck/${healthcheck}/update`)
          .then(response => {
            this.status = response.data;
          })
          .catch(e => {
            console.log("error updating Healthcheck", e);
            alert("error updating healthcheck");
          })
          .finally(() => {
            useGeneralStore().isLoading = false;
          });
    },
    fetchStatus() {
      if (this.isFetching) {
        return;
      }
      this.isFetching = true;
      $axios.get("/monitoring/status")
          .then(response => {
            this.status = response.data;
          })
          .catch(e => {
            console.log("error fetching status", e);
            alert("error fetching status");
          })
          .finally(() => {
            this.isFetching = false;
          });
    },
  },
});
