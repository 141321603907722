<template>
  <PageWrapper title="Waiting">
    you are in command
    <iButton @click="relinquishCommand">Finish</iButton>
  </PageWrapper>
</template>

<script>
import { useStateStore } from "@/stores/state-store";
import { mapActions } from "pinia";
import PageWrapper from "@/components/PageWrapper.vue";

export default {
  name: "CommandCenter",
  components: {
    PageWrapper,
  },
  computed: {
    myTitle() {
      return "my-title";
    },
  },
  methods: {
    ...mapActions(useStateStore, [
      "relinquishCommand",
    ]),
  },
};
</script>
