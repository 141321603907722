<template>
  <iHeading>
    Monitoring
  </iHeading>
  <iRow>
    <iCard v-for="(microserviceStatus, microserviceName) in microservices" :key="microserviceName">
      >
      <template #header>
        <iRow vertical-align="middle" align="between">
          <iSubHeading>{{ microserviceName }}</iSubHeading>
          <iText>branch: {{ microserviceStatus.CurrentBranch.branchName }}</iText>
        </iRow>
      </template>
      <template #body>
        <iRow v-if="microserviceStatus.UnCommittedChanges.hasUnCommittedChanges">
          <iIcon icon="alert" />
          <iText>
            Uncommitted Staged Changes
          </iText>
        </iRow>
        <iRow v-if="microserviceStatus.UnStagedChanges.hasUnStagedChanges">
          <iIcon icon="alert" />
          <iText>
            Unstaged Changes
          </iText>
        </iRow>
        <iRow v-if="microserviceStatus.UnTrackedChanges.hasUnTrackedChanges">
          <iIcon icon="alert" />
          <iText>
            UnTracked Changes
          </iText>
        </iRow>
        <iRow v-if="microserviceStatus.VclLinked.isLinked">
          <iText>
            VCL is Linked <button @click="update(microserviceName, 'VclLinked')">
              un link
            </button>
          </iText>
        </iRow>
        <iRow v-else>
          <iText>
            VCL is NOT Linked <button @click="update(microserviceName, 'VclUnLinked')">
              link
            </button>
          </iText>
        </iRow>
        <ul>
          <li v-if="microserviceStatus.MainBranch.commitsBehind > 0">
            {{ microserviceStatus.MainBranch.commitsBehind }} commits behind origin
            <button @click="update(microserviceName, 'MainBranch')">
              update main
            </button>
          </li>
          <li v-else>
            Main branch is in sync with origin
          </li>

          <li v-if="microserviceStatus.CurrentBranch.isMain">
            You are on the main branch
          </li>
          <li v-else-if="microserviceStatus.CurrentBranch.commitsBehind > 0">
            you are on branch "{{ microserviceStatus.CurrentBranch.branchName }}"
            which is {{ microserviceStatus.CurrentBranch.commitsBehind }} commits behind main
          </li><li v-else>
            you are on branch "{{ microserviceStatus.CurrentBranch.name }}" which is in sync with origin/main
          </li>
        </ul>
      </template>
    </iCard>
  </iRow>
</template>

<script>
import { mapActions, mapState } from "pinia";
import useMicroServiceStore from "@/stores/micro-service-store";

export default {
  name: "Monitoring",
  computed: {
    ...mapState(useMicroServiceStore, { microservices: "status" }),
  },
  methods: {
    ...mapActions(useMicroServiceStore, ["update"]),
  },
};
</script>
