<template>
  <iRow>
    <iColumn>
      <iHeading v-if="commander">
          {{ commander.name }} is in command
      </iHeading>
      <iHeading v-else>
          The queue is empty
      </iHeading>
      <br />
      {{ currentAction }}
      <ul>
        <li>
          commit {{ revision }}
        </li>
        <li>
          started {{ elapsedTime }} ({{ timeStarted }})
        </li>
        <li>
          estimated {{ estimatedTimeRemaining }} remaining
        </li>
        <li>

      Merged PRs
      <ul>
        <li>
          9ba27f0c merged 10s ago - drag and drop improvements for story editor
        </li>
        <li>
          763ab78f merged 5m ago - drag and drop improvments for dynamic component builder layers sidebar
        </li>
      </ul>
        </li>
      </ul>
    </iColumn>
  </iRow>
</template>

<script>
import { useStateStore } from "@/stores/state-store";

export default {
  name: "CurrentActivity",
  props: {
  },
  computed: {
    commander() {
      return useStateStore().commander;
    },
  },
};
</script>
