import { defineStore } from "pinia";
import { useGeneralStore } from "@/stores/general-store";

export const useStateStore = defineStore("state-store", {
  state: () => ({
    isFetching: false,
    queue: [],
    users: {},
    myUserId: 0,
    timestampLastUpdate: 0,
    dropLoading: {},
    kickLoading: {},
    joinLoading: false,
  }),

  getters: {
    inCommand() {
      if (this.queue.length > 0 && this.queue[0].userId == this.myUserId) {
        return true;
      }
      return false;
    },

    commander() {
      const queue = this.queue;
      if (queue.length < 1) {
        return null;
      }
      const commanderUserId = queue[0].userId;
      return this.users[commanderUserId];
    },

    myUser() {
      return this.users[this.myUserId];
    },
  },

  actions: {

    startPolling() {
      setInterval(() => {
        this.fetchStatus();
      }, 5000);
    },

    getMessageFromError(error) {
      if (error) {
        // parse
      }
      return "something went wrong";
    },

    updateCurrentUserId(id) {
      this.myUserId = id;
    },

    updateState(state) {
      this.queue = state.queue;
      this.users = state.users;
      this.timestampLastUpdate = state.timestampLastUpdate;
    },

    post(uri, data, finallyCallback) {
      useGeneralStore().startLoading();
      this.apiPost(
        uri,
        data,
        (state) => {
          this.updateState,
          useGeneralStore().stopLoading();
        },
        (e) => {
          useGeneralStore().stopLoading();
          console.log('error in apiPost', e);
          alert('error');
        },
      );
    },

    kickFromQueue(userId, timestamp) {
      if (this.kickLoading[timestamp]) {
        return;
      }
      this.kickLoading[timestamp] = true;
      this.post("/queue/kick", { user_id: userId, timestamp }, () => this.kickLoading[timestamp] = false);
    },

    relinquishCommand() {
      if (this.queue.length < 1) {
        throw new Error('you cannot relinquish command because no one is in command');
      }
      if (!this.inCommand) {
        throw new Error('you cannot relinquish command because you are not in command');
      }
      return this.dropFromQueue(this.queue[0].timestamp);
    },

    dropFromQueue(timestamp) {
      if (this.dropLoading[timestamp]) {
        return;
      }
      this.dropLoading[timestamp] = true;
      this.post("/queue/drop", { timestamp }, () => this.dropLoading[timestamp] = false);
    },

    jumpQueue() {
      if (this.joinLoading) {
        return;
      }
      this.joinLoading = true;
      this.post("/queue/jump", {}, () => this.joinLoading = false);
    },

    joinQueue() {
      if (this.joinLoading) {
        return;
      }
      this.joinLoading = true;
      this.post("/queue/join", {}, () => this.joinLoading = false);
    },

    fetchStatus() {
      if (this.isFetching) {
        return;
      }
      this.isFetching = true;
      this.apiGet(
        "/state",
        {},
        this.updateState,
        (e) => {
          console.log("error fetching status", e);
          alert("error fetching status");
        },
        () => {
          this.isFetching = false;
        }
      );
    },

  },
});
