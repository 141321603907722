<template>
  <form v-if="debug" class="starfield-debug">
    <div class="debug-close-button" @click="debug = false">
      <div class="debug-close-button-text">
        x
      </div>
    </div>
    <div>
      <label for="isHyperspace">
        <input id="isHyperspace" v-model="hyperspaceOverwrite" type="checkbox">Hyperspace
      </label>

      <label for="warpFactor">
        | Warp Factor
      </label>
      <input
        id="warpFactor"
        v-model="warpFactor"
        type="number"
        name="warpFactor"
        :placeholder="warpFactor"
        min="1"
        max="1000"
      >
    </div>

    <div>
      <label for="easing">Easing: </label>
      <input
        id="easing"
        v-model="easing"
        type="number"
        name="easing"
        :placeholder="easing"
        min="0"
        max="100"
        step="1"
      >
    </div>

    <div>
      <label for="quantity">Number of stars: </label>
      <input
        id="quantity"
        v-model="quantity"
        type="number"
        name="quantity"
        :placeholder="quantity"
        min="0"
        max="8192"
        step="1"
        @change="reset"
      >
    </div>

    <div>
      <label for="starColor">Star color:</label>
      <input
        v-model="starColor"
        type="color"
        @change="reset"
      >
    </div>

    <div>
      <label for="bgColor">Background color:</label>
      <input v-model="bgColor" type="color">
    </div>

    <div>
      <label for="speed">Speed: </label>
      <input
        id="speed"
        v-model="speed"
        type="number"
        name="speed"
        :placeholder="speed"
        min="-100"
        max="100"
        step="1"
      >
    </div>

    <div
      style="margin-top: 20px; padding-top: 10px; border-top: 1px solid rgba(255, 255, 255, 0.25); font-size: 0.8em"
    >
      <input
        type="button"
        value="Start"
        style="color: #000"
        :disabled="state.running"
        @click="start()"
      > |
      <input
        type="button"
        value="Stop"
        style="color: #000"
        :disabled="!state.running"
        @click="stop()"
      > |
      <input
        type="button"
        value="Reset"
        style="color: #000"
        @click="reset()"
      >
    </div>
  </form>
</template>

<script>
import { useStarfieldStore } from "@/stores/starfield-store";
import { mapState, mapWritableState } from "pinia";

export default {
  name: "StarfieldDebug",
  emits: ["stop", "start", "reset"],
  computed: {
    ...mapWritableState(useStarfieldStore, [
      "debug",
      "hyperspaceOverwrite",
      "warpFactor",
      "easing",
      "quantity",
      "starColor",
      "bgColor",
      "speed",
      "state",
    ]),
    ...mapState(useStarfieldStore, ["compColors"]),
  },
  created() {
    window.addEventListener("keydown", this.openDebugWindowOnKeyMapping);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.openDebugWindowOnKeyMapping);
  },
  methods: {
    openDebugWindowOnKeyMapping(e) {
      if (e.code === "KeyA" && e.metaKey && e.altKey) {
        this.debug = !this.debug;
      }
    },
    stop() {
      this.$emit("stop");
    },
    start() {
      this.$emit("start");
    },
    reset() {
      this.$emit("reset");
    },
  },
  styleGuide: () => ({
    starfieldDebugBackground: { "color.background": "darker" },
    starfieldDebugBorderColor: { "color.border": "dark" },
    starfieldDebugBorderRadius: { "size.borderRadius": "standard" },
    starfieldBorderWidth: { "size.border": "thin" },
    starfieldDebugPadding: { "size.spacing": "standard" },
    starfieldDebugCloseButtonPadding: { "size.spacing": "extraSmall" },
  }),
};
</script>


<style scoped>
.starfield-debug {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #fff;
  font-size: 16px;
  z-index: 1;
  line-height: 2em;
  font-family: 'Helvetica Neue', Helvetica, arial, sans-serif;
  font-weight: 300;
  padding: v-bind("$getStyles.starfieldDebugPadding");
  border-radius: v-bind("$getStyles.starfieldDebugBorderRadius");
  border: v-bind("$getStyles.starfieldBorderWidth") solid v-bind("$getStyles.starfieldDebugBorderColor");
  background: v-bind("$getStyles.starfieldDebugBackground");
}

.debug-close-button {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: v-bind("$getStyles.starfieldDebugBorderRadius");
  border: v-bind("$getStyles.starfieldBorderWidth") solid v-bind("$getStyles.starfieldDebugBorderColor");
  background: v-bind("$getStyles.starfieldDebugBackground");
  padding: v-bind("$getStyles.starfieldDebugCloseButtonPadding");
}

.debug-close-button-text {
  position: relative;
  bottom: 1px;
}
</style>
