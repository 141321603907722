import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "@/stores/general-store";
import R2D2Failure from "@/audio/r2-d2-01.mp3";
import R2D2Success from "@/audio/r2-d2-22.mp3";
import R2D2Trigger from "@/audio/r2-d2-07.mp3";
import ImTooWeak from "@/audio/im-too-weak.mp3";
import LackOfFaith from "@/audio/lack-of-faith.mp3";
import Order66 from "@/audio/order-66.mp3";
import RogerRoger from "@/audio/roger-roger.mp3";
import DoesNotShareOptimism from "@/audio/does-not-share-optimism.mp3";

export const usePullRequestStore = defineStore("pull-request-store", {
  state: () => ({
    loadingOpenPullRequests: false,
    loadingMergedPullRequests: false,
    loadingMergePullRequestNumber: -1,
    loadingMergeApprovedPullRequest: false,
    onlyShowMergablePrs: true,
    onlyShowMyPrs: true,
    openPullRequests: {},
    mergedPullRequests: [],
    currentBuildImageStatus: "",
    currentDeployStagingStatus: "",
    currentDeployProductionStatus: "",
    repositoryName: "",
    failureAudioLight: new Audio(R2D2Failure),
    successAudioLight: new Audio(R2D2Success),
    triggerAudioLight: new Audio(R2D2Trigger),
    triggerAudioDark: new Audio(RogerRoger),
    failureAudioDark: new Audio(ImTooWeak),
    successBuildAudioDark: new Audio(Order66),
    successStagingAudioDark: new Audio(LackOfFaith),
    successProductionAudioDark: new Audio(DoesNotShareOptimism),
  }),
  getters: {
    orderedOpenPullRequests() {
      return Object.values(this.openPullRequests).sort((pullRequestA, pullRequestB) => {
        if (pullRequestA.number > pullRequestB.number) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    isRunningAction() {
      return this.isRunningBuildImage || this.isRunningDeployStaging || this.isRunningDeployProduction;
    },
    isRunningBuildImage() {
      return this.currentBuildImageStatus.startsWith("QUEUED") || this.currentBuildImageStatus.startsWith("RUNNING");
    },
    isRunningDeployStaging() {
      return this.currentDeployStagingStatus.startsWith("QUEUED") || this.currentDeployStagingStatus.startsWith("RUNNING");
    },
    isRunningDeployProduction() {
      return this.currentDeployProductionStatus.startsWith("QUEUED") || this.currentDeployProductionStatus.startsWith("RUNNING");
    },
    isDeployableAppType() {
      return useGeneralStore().getApp(this.repositoryName).type === "microservice";
    },
  },
  actions: {
    mergeApprovedPullRequest(pullRequestNumber) {
      if (this.loadingMergeApprovedPullRequest) {
        return;
      }
      this.loadingMergeApprovedPullRequest = true;
      this.loadingMergePullRequestNumber = pullRequestNumber;
      $axios.post("/pull-request/merge", {
        pull_request_number: pullRequestNumber,
        repository_name: this.repositoryName,
      }).then(response => {
        if (response.data.success) {
          delete this.openPullRequests[pullRequestNumber];
          this.mergedPullRequests = response.data.mergedPullRequests;
        }
        this.loadingMergePullRequestNumber = -1;
        this.loadingMergeApprovedPullRequest = false;
      });
    },
    fetchMergedPullRequests() {
      if (this.loadingMergedPullRequests) {
        return;
      }
      this.loadingMergedPullRequests = true;
      $axios.get(`/pull-request/merged/${this.repositoryName}`)
          .then(response => {
            this.mergedPullRequests = response.data.mergedPullRequests;
            this.loadingMergedPullRequests = false;
          });
    },
    fetchOpenPullRequests() {
      if (this.loadingOpenPullRequests) {
        return;
      }
      this.loadingOpenPullRequests = true;
      $axios.get(`/pull-request/open/${this.repositoryName}`)
          .then(response => {
            this.openPullRequests = response.data.openPullRequests;
            this.loadingOpenPullRequests = false;
          });
    },
    buildImage(mergedPullRequestSha) {
      this.playTriggerAudio();
      $axios.post(`/pull-request/build/${this.repositoryName}/sha/${mergedPullRequestSha}`)
          .then(response => {
            this.currentBuildImageStatus = response.data.status;
          });
    },
    deployToStaging(mergedPullRequestSha) {
      this.playTriggerAudio();
      $axios.post(`/pull-request/deploy/${this.repositoryName}/staging/sha/${mergedPullRequestSha}`)
          .then(response => {
            this.currentDeployStagingStatus = response.data.status;
          });
    },
    deployToProduction(mergedPullRequestSha) {
      this.playTriggerAudio();
      $axios.post(`/pull-request/deploy/${this.repositoryName}/production/sha/${mergedPullRequestSha}`)
          .then(response => {
            this.currentDeployProductionStatus = response.data.status;
          });
    },
    checkDeploymentStatus() {
      $axios.get(`/pull-request/deploy/${this.repositoryName}/status`)
          .then(response => {
            this.playAudio(response.data.status);
            this.currentBuildImageStatus = response.data.status.build;
            this.currentDeployStagingStatus = response.data.status.staging;
            this.currentDeployProductionStatus = response.data.status.production;
          });
    },
    setRepositoryName(repositoryName) {
      this.repositoryName = repositoryName;
    },
    playTriggerAudio() {
      if (!useGeneralStore().audioOn) {
        return;
      }
      if (useGeneralStore().darkSide) {
        this.triggerAudioDark.play();
      } else {
        this.triggerAudioLight.play();
      }
    },
    playAudio(newStatuses) {
      if (!useGeneralStore().audioOn) {
        return;
      }
      if (useGeneralStore().darkSide) {
        this.playSuccessOrFailureAudioDark(newStatuses);
      } else {
        this.playSuccessOrfailureAudioLight(newStatuses);
      }
    },
    playSuccessOrFailureAudioDark(newStatuses) {
      if (this.currentBuildImageStatus.startsWith("RUNNING") && newStatuses.build.startsWith("COMPLETE")) {
        this.successBuildAudioDark.play();
      }
      if (this.currentDeployStagingStatus.startsWith("RUNNING") && newStatuses.staging.startsWith("COMPLETE")) {
        this.successStagingAudioDark.play();
      }
      if (this.currentDeployProductionStatus.startsWith("RUNNING") && newStatuses.production.startsWith("COMPLETE")) {
        this.successProductionAudioDark.play();
      }
      if (this.currentBuildImageStatus.startsWith("RUNNING") && newStatuses.build.startsWith("FAILED")) {
        this.failureAudioDark.play();
      }
      if (this.currentDeployStagingStatus.startsWith("RUNNING") && newStatuses.staging.startsWith("FAILED")) {
        this.failureAudioDark.play();
      }
      if (this.currentDeployProductionStatus.startsWith("RUNNING") && newStatuses.production.startsWith("FAILED")) {
        this.failureAudioDark.play();
      }
    },
    playSuccessOrfailureAudioLight(newStatuses) {
      if (this.currentBuildImageStatus.startsWith("RUNNING") && newStatuses.build.startsWith("COMPLETE")) {
        this.successAudioLight.play();
      }
      if (this.currentDeployStagingStatus.startsWith("RUNNING") && newStatuses.staging.startsWith("COMPLETE")) {
        this.successAudioLight.play();
      }
      if (this.currentDeployProductionStatus.startsWith("RUNNING") && newStatuses.production.startsWith("COMPLETE")) {
        this.successAudioLight.play();
      }
      if (this.currentBuildImageStatus.startsWith("RUNNING") && newStatuses.build.startsWith("FAILED")) {
        this.failureAudioLight.play();
      }
      if (this.currentDeployStagingStatus.startsWith("RUNNING") && newStatuses.staging.startsWith("FAILED")) {
        this.failureAudioLight.play();
      }
      if (this.currentDeployProductionStatus.startsWith("RUNNING") && newStatuses.production.startsWith("FAILED")) {
        this.failureAudioLight.play();
      }
    },
  },
});
