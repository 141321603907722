import { defineStore } from "pinia";
import onecolor from "onecolor";
import UUID from "@/helpers/uuid-helper";
import { useGeneralStore } from "@/stores/general-store";

export const useStarfieldStore = defineStore("starfield-store", {
  state: () => ({
    debug: false,
    hyperspaceOverwrite: false,
    warpFactor: 10,
    easing: 1,
    quantity: 1024,
    starColor: "rgba(255,255,255,1)",
    bgColor: "rgba(0,0,0,1)",
    speed: 1.5,
    state: {
      init: true, // Init?
      canvas: false, // Canvas?
      start: false, // Start animation?
      stop: false, // Stop animation?
      destroy: false,
      reset: false, // Reset animation?
      uid: UUID.generate(),
      running: false,
    },
  }),
  getters: {
    hyperspace() {
      return this.hyperspaceOverwrite || useGeneralStore().isLoading;
    },
    compColors() {
      return {
        stars: onecolor(this.starColor).hex(),
        bg: onecolor(this.bgColor).hex(),
      };
    },
    compSpeed() { // computed speed
      return {
        lyph: this.hyperspace ? this.speed * this.warpFactor : this.speed, // light-years per hour
      };
    },
    colors() {
      return {
        fill: this.hyperspace ? onecolor(this.bgColor).cssa() : this.bgColor,
      };
    },
    ids() { // Compute IDs
      return {
        cid: "canvas-" + this.state.uid,
        vid: "viewport-" + this.state.uid,
      };
    },
    ratio() { // Compute IDs
      return {
        computed: this.quantity / 2,
      };
    },
  },
  actions: {
    init() {
      this.state.init = true;
      this.state.canvas = true;
      this.state.start = true;
      this.state.running = false;
      this.state.stop = false;
      this.state.reset = false;
    },
    reset() {
      this.state.init = false;
      this.state.canvas = false;
      this.state.star = false;
      this.state.start = false;
      this.state.stop = false;
      this.state.destroy = true;
    },
  },
});
